<template>
  <v-container fill-height fluid class="login-container">
    <div class="top-left-logo">
      <app-logo></app-logo>
    </div>

    <v-row align="center" justify="center" style="margin-top: 5vh">
      <v-col cols="12" sm="10" md="6" lg="4">
        <p v-if="$route.query.verified" class="w-auto rounded success py-2">
          Email verified, kindly login to continue
        </p>
        <v-card flat class="login-card rounded-xl mx-auto">
          <v-card-title class="text-center d-block pb-1 pt-8">
            <h1 class="sign-in-title">Sign In</h1>
          </v-card-title>

          <v-card-subtitle class="text-center dark pb-4 pb-10">
            Welcome back! Let's continue with,
          </v-card-subtitle>

          <v-form lazy-validation ref="form" class="px-8 pb-6">
            <v-text-field
              prepend-inner-icon="mdi-at"
              placeholder="Your Email"
              outlined
              class="mb-7 login-field"
              hide-details
              background-color="#ffffff"
              :rules="validate.required('email')"
              type="email"
              v-model="form.email"
            ></v-text-field>

            <v-text-field
              prepend-inner-icon="mdi-lock-outline"
              placeholder="Your Password"
              :rules="validate.required('password')"
              v-model="form.password"
              :type="showPassword ? 'text' : 'password'"
              outlined
              class="login-field"
              hide-details
              background-color="#ffffff"
              :append-icon="
                showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
              "
              @click:append="showPassword = !showPassword"
            ></v-text-field>

            <div class="d-flex justify-space-between align-center mt-3">
              <v-checkbox
                label="Remember me"
                color="primary"
                class="mt-0 pt-0"
                hide-details
                dense
              >
                <template v-slot:label>
                  <span class="text-body-2 dark">Remember me</span>
                </template>
              </v-checkbox>
              <router-link
                class="forgot-password-link text-body-2"
                to="/forgot-password"
              >
                Forgot password?
              </router-link>
            </div>

            <v-btn
              block
              rounded
              color="primary"
              height="48"
              dark
              class="mt-8 mb-10 signin-btn"
              elevation="0"
              @click="$refs.form.validate() ? signIn() : null"
              :loading="loading"
            >
              Sign In
            </v-btn>

            <div class="text-center mt-4 mb-3 account-text">
              Don't have an account?
              <router-link to="/sign_up" class="signup-link"
                >Sign Up</router-link
              >
            </div>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import AppLogo from "../../../components/Logo";
import FormValidation from "../../../utils/FormValidation";
import ResponseHelper from "../../../utils/ResponseHelper";

export default {
  name: "NewLogin",
  components: {
    AppLogo,
  },
  data() {
    return {
      form: {
        remember_me: false,
      },
      validate: new FormValidation(),
      toast: new ResponseHelper(),
      loading: false,
      showPassword: false,
    };
  },
  methods: {
    ...mapActions("auth", ["sign_in"]),
    async signIn() {
      this.loading = true;
      try {
        const response = await this.sign_in(this.form);
        this.loading = false;
        const beneficiaries = response.data["beneficiaries"].filter(
          (x) => x.role !== null
        );
        if (beneficiaries.length > 0) {
          await this.$router.replace({ name: "SelectAccount" });
          return;
        }
        await this.$router.replace(this.$route.query.redirect || "dashboard");
      } catch (error) {
        this.loading = false;
        this.toast.sendError(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  min-height: 100vh;
  position: relative;
}

.top-left-logo {
  position: absolute;
  top: 24px;
  left: 24px;
  z-index: 1;
}

.login-card {
  background-color: white;
  border-radius: 16px !important;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05) !important;
}

.sign-in-title {
  font-size: 26px;
  font-weight: bold;
  color: #111 !important;
  margin-bottom: 25px;
}

.login-field {
  ::v-deep .v-input__slot {
    min-height: 45px !important;
    border-radius: 8px;
    font-size: 15px;
  }

  ::v-deep .v-input__prepend-inner {
    margin-top: 10px !important;
    margin-right: 8px !important;
  }

  ::v-deep .v-input__append-inner {
    margin-top: 10px !important;
  }
}

.forgot-password-link {
  color: #1768f3 !important;
  text-decoration: none;
  font-size: 14px;
}

.signin-btn {
  font-weight: 700 !important;
  text-transform: none;
  letter-spacing: 0;
  font-size: 16px;
  color: #ffffff !important;
}

.signup-link {
  color: #1768f3 !important;
  text-decoration: none;
  font-weight: 500;
}

.account-text {
  color: #111;
  font-size: 14px;
}

.dark {
  color: #222 !important;
}

::v-deep .v-input--selection-controls__ripple {
  display: none !important;
}

::v-deep .v-input--selection-controls .v-input__slot {
  margin-bottom: 0;
}

::v-deep .v-input--checkbox .v-label {
  font-size: 14px !important;
}
</style>
